import { Button, Tab, Tabs } from '@mui/material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { deleteQuestion, duplicateQuestion, getQuestions } from 'api/question'
import columnRenderers, { PLACEHOLDER } from 'components/column-renderers'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import PageTitle from 'components/page/PageTitle'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { getTitle } from 'components/tabs/TabsTitle'
import { reactDomLinkStyles } from 'const'
import { columnCreatedBy, columnUpdatedBy, getActionColumn, useDuplicateControl } from 'features/UI'
import { useGridControl } from 'hooks/useGridControl'
import { useRefreshTable } from 'hooks/useRefreshTable'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import { useShowControl } from 'hooks/useShowControl'
import { useTabsControl } from 'hooks/useTabsControl'
import React, { useCallback, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { BaseTableRequest, EQuestionType, FilterModel, Question } from 'types'
import { useGetDataSource, withIdParam } from 'utils'
import { QuestionModalCreate } from '../QuestionModalCreate/QuestionModalCreate'
import moment from 'moment'
import { useExportCSV } from 'hooks/useExportCSV'
import { useQuestionCSVField } from 'features/Questions/hooks/useQuestionCSVField'

const questionTabTitle = {
  [EQuestionType.question]: 'Quiz/Exam Questions Overview',
  [EQuestionType.query]: 'Query Questions Overview',
}

export const QuestionTablePage = () => {
  const navigate = useNavigate()

  const { selectedTab, handleChangeSelectedTab } = useTabsControl<EQuestionType>(
    EQuestionType.question,
  )

  const getQuestionsWrapper = useCallback(
    (params: BaseTableRequest) => {
      const baseFilter: FilterModel = params.filter || {}
      const userFilter: FilterModel = {
        ...baseFilter,
        type: {
          type: 'text',
          op: 'equals',
          value: selectedTab,
        },
      }
      return getQuestions({
        ...params,
        filter: userFilter,
      })
    },
    [selectedTab],
  )

  const getDataSource = useGetDataSource(getQuestionsWrapper)
  const { onGridReady, gridApi, columnApi } = useGridControl(getDataSource)

  const [isOpenAddQuestionModal, openAddQuestionModal, closeAddQuestionModal] = useShowControl()

  useRefreshTable({ params: { selectedTab }, gridApi })

  const { DuplicateModal, setItem: setQuestionToDuplicate } = useDuplicateControl<Question>({
    entityName: 'Question',
    apiMethod: duplicateQuestion,
    routeSuccess: routes.questionEdit,
  })

  const { idToRemove, openDeleteModal, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: deleteQuestion,
      successCallback: () => gridApi?.purgeInfiniteCache(),
      warning: "Can't remove question",
    })

  const onClickEdit = useCallback(
    (id: number) => {
      navigate(withIdParam(routes.questionEdit, id))
    },
    [navigate],
  )

  const onDuplicateClick = useCallback(
    (id: number, props: ICellRendererParams<Question>) => {
      setQuestionToDuplicate(props.data)
    },
    [setQuestionToDuplicate],
  )

  const actions = useMemo(() => {
    return [
      {
        name: 'Edit',
        onClick: onClickEdit,
      },
      { name: 'Delete', onClick: openDeleteModal },
      { name: 'Duplicate', onClick: onDuplicateClick },
    ]
  }, [onClickEdit, onDuplicateClick, openDeleteModal])

  const questionCellRenderer = useCallback(
    (props: ICellRendererParams<{ name: string; id: string }>) => {
      const itemId = props?.data?.id
      const link = `${routes.questionEdit}?id=${itemId}`
      return (
        <Link style={reactDomLinkStyles} to={link}>
          {props.data?.name || PLACEHOLDER}
        </Link>
      )
    },
    [],
  )

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Question Name',
        cellRenderer: questionCellRenderer,
        filter: 'agTextColumnFilter',
        colId: 'name',
      },
      {
        headerName: 'Creation Date',
        cellRenderer: columnRenderers.createdAt,
        colId: 'createdAt',
      },
      columnCreatedBy(),
      {
        headerName: 'Last Edited',
        cellRenderer: columnRenderers.updatedAt,
        minWidth: 220,
        colId: 'updatedAt',
      },
      columnUpdatedBy(),
      { ...getActionColumn(actions) },
    ]
  }, [actions, questionCellRenderer])

  const left = (
    <>
      <Tabs value={selectedTab} onChange={handleChangeSelectedTab} indicatorColor="primary">
        <Tab value={EQuestionType.question} label={getTitle('Quiz / Exam')} />
        <Tab value={EQuestionType.query} label={getTitle('Queries')} />
      </Tabs>
    </>
  )

  const csvFields = useQuestionCSVField()

  const { csvLoading, onExportCSV } = useExportCSV(
    getQuestionsWrapper,
    gridApi,
    columnApi,
    csvFields,
    `${questionTabTitle[selectedTab]}_${moment().format('YYYY-MM-DD')}.csv`,
  )

  return (
    <>
      <PageTitle>{questionTabTitle[selectedTab]}</PageTitle>

      <TableToolbar left={left} csvLoading={csvLoading} exportCSVEnable onExportCSV={onExportCSV}>
        <Button variant="outlined" sx={{ minWidth: 120 }} onClick={openAddQuestionModal}>
          Add Question
        </Button>
      </TableToolbar>

      <InfinityAgGrid pagination columnDefs={columnDefs} onGridReady={onGridReady} />

      <QuestionModalCreate isOpen={isOpenAddQuestionModal} onClose={closeAddQuestionModal} />

      <ConfirmRemoveModal
        entityToRemove="Question"
        loading={removeLoading}
        isOpen={idToRemove !== null}
        handleConfirm={handleConfirmRemove}
        handleClose={closeDeleteModal}
      />

      {DuplicateModal}
    </>
  )
}
