import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { getTimelines } from 'api/timelines'
import { BaseTableRequest, CareArticleType, ETimelinePostType, TimelineItem } from 'types'
import { RootState } from '../store'

export const getTimelineById = createAsyncThunk(
  'timeline/by-user-id',
  async (request: BaseTableRequest, thunkAPI) => {
    try {
      const response = await getTimelines(request)
      return response.data.rows
    } catch (e) {
      thunkAPI.rejectWithValue({})
    }
  },
)

export const timelineSlice = createSlice({
  name: 'timeline',
  initialState: {
    timeline: null as TimelineItem[] | null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTimelineById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTimelineById.fulfilled, (state, action) => {
        state.isLoading = false
        if (!action.payload) {
          state.timeline = null
        } else {
          // replace type for task care articles
          state.timeline = action.payload.map((item) => {
            if (
              item.type === ETimelinePostType.careArticle &&
              item.careArticle?.type === CareArticleType.Task
            ) {
              return {
                ...item,
                type: ETimelinePostType.taskArticle,
              }
            }

            return item
          })
        }
      })
      .addCase(getTimelineById.rejected, (state) => {
        state.isLoading = false
        state.timeline = null
      })
      .addCase('currentUser/logout', (state) => {
        state.timeline = []
      })
  },
})

export const selectTimelineSlice = (state: RootState) => state.timeline

export const selectTimelineItems = createSelector(selectTimelineSlice, (slice) => slice.timeline)
