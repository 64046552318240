import { Button } from '@mui/material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { createTag, deleteTag, getTags, updateTag } from 'api/tags'
import columnRenderers from 'components/column-renderers'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import ModalWithTextInput from 'components/modals/simple-modals/ModalWithTextInput'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { getActionColumn } from 'features/UI'
import { useChangeEntity } from 'hooks/useChangeEntity'
import { useGridControl } from 'hooks/useGridControl'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Nullish, Tag, TagRequestUpdate } from 'types'
import { useGetDataSource } from 'utils'
import { removeTag } from '../../state/Tags.slice'
import { useExportCSV } from 'hooks/useExportCSV'
import moment from 'moment'
import { useTagCSVField } from 'features/Tags/hooks/useTagCSVField'

export function TagsPage() {
  const dispatch = useDispatch()
  const [editedTag, setEditedTag] = useState<Nullish<Tag>>(null)
  const getDataSource = useGetDataSource(getTags)
  const { onGridReady, gridApi, columnApi } = useGridControl(getDataSource)

  const [loading, setLoading] = useState(false)

  const [onSubmit, openAddModal, isOpenAddModal, closeAddModal] = useChangeEntity({
    setLoading,
    onSuccess: () => {
      gridApi?.purgeInfiniteCache()
    },
    requestFunc: createTag,
    existedNameWarning:
      "Name must be unique. Can't create the learn library topic with existing name",
    warning: "Can't create the learn library topic",
  })

  const [onUpdate, openEditModal, isOpenEditModal, closeEditModal] = useChangeEntity({
    setLoading,
    onSuccess: () => {
      gridApi?.purgeInfiniteCache()
    },
    requestFunc: updateTag,
    existedNameWarning: "Can't rename tag to existed name",
    warning: "Can't update tag",
  })

  const { idToRemove, openDeleteModal, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: deleteTag,
      warning: "Can't delete tag",
      successCallback: () => {
        if (idToRemove) {
          dispatch(removeTag({ tagId: idToRemove }))
        }
        gridApi?.purgeInfiniteCache()
      },
    })

  const handleDelete = useCallback(
    (tagId: number) => {
      openDeleteModal(tagId)
    },
    [openDeleteModal],
  )

  const handleCreateTag = useCallback(
    (name: string) => {
      onSubmit({ name, type: 'content', isFilterable: false })
    },
    [onSubmit],
  )

  const handleEditTag = useCallback(
    (tag: TagRequestUpdate) => {
      gridApi?.showLoadingOverlay()
      onUpdate(tag)
    },
    [gridApi, onUpdate],
  )

  const handleEditTagFromModal = useCallback(
    (name: string) => {
      if (!editedTag) {
        return
      }

      handleEditTag({
        name,
        id: editedTag.id,
        type: editedTag.type,
        isFilterable: editedTag.isFilterable,
      })

      setEditedTag(null)
      closeEditModal()
    },
    [closeEditModal, editedTag, handleEditTag],
  )

  const actions = useMemo(() => {
    return [
      {
        name: 'Edit',
        onClick: (_: number, props: ICellRendererParams<Tag>) => {
          if (props.data) {
            setEditedTag(props.data)
          }
          openEditModal()
        },
      },
      {
        name: 'Delete',
        onClick: handleDelete,
      },
    ]
  }, [handleDelete, openEditModal])

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Tag Name',
        field: 'name',
        flex: 1,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Creation Date',
        cellRenderer: columnRenderers.createdAt,
        colId: 'createdAt',
      },
      {
        headerName: 'Last Edited',
        cellRenderer: columnRenderers.updatedAt,
        minWidth: 220,
        colId: 'updatedAt',
      },
      {
        headerName: 'Use as Filter',
        field: 'isFilterable',
        cellRenderer: columnRenderers.checkbox((data: Tag, checked: boolean) => {
          handleEditTag({
            name: data.name,
            id: data.id,
            type: data.type,
            isFilterable: checked,
          })
        }),
      },
      { ...getActionColumn(actions) },
    ]
  }, [actions, handleEditTag])

  const csvFields = useTagCSVField()

  const { csvLoading, onExportCSV } = useExportCSV(
    getTags,
    gridApi,
    columnApi,
    csvFields,
    `Tags Overview_${moment().format('YYYY-MM-DD')}.csv`,
  )

  return (
    <>
      <TableToolbar
        title="Tags Overview"
        exportCSVEnable
        csvLoading={csvLoading}
        onExportCSV={onExportCSV}
      >
        <Button variant="outlined" sx={{ minWidth: 120 }} onClick={openAddModal}>
          Create Tag
        </Button>
      </TableToolbar>

      <InfinityAgGrid pagination columnDefs={columnDefs} onGridReady={onGridReady} />

      <ModalWithTextInput
        title="Create Tag"
        label="Tag Name"
        buttonText="Create"
        isOpen={isOpenAddModal}
        handleClose={closeAddModal}
        loading={loading}
        onSubmit={handleCreateTag}
      />

      <ModalWithTextInput
        title="Update Tag"
        label="Tag Name"
        value={editedTag?.name}
        buttonText="Save"
        isOpen={isOpenEditModal}
        handleClose={closeEditModal}
        loading={loading}
        onSubmit={handleEditTagFromModal}
      />

      <ConfirmRemoveModal
        entityToRemove="Tag"
        loading={removeLoading}
        isOpen={idToRemove !== null}
        handleConfirm={handleConfirmRemove}
        handleClose={closeDeleteModal}
      />
    </>
  )
}
