import { useMemo } from 'react'
import { CSVField } from 'hooks/useExportCSV'
import { csvColumnCreatedBy, csvColumnUpdatedBy } from 'features/UI'
import { formatDate } from 'utils'

export function useQuestionCSVField() {
  return useMemo<CSVField[]>(
    () => [
      {
        label: 'Question name',
        value: 'name',
      },
      {
        label: 'Creation Date',
        value: ({ createdAt }) => formatDate(createdAt),
      },
      csvColumnCreatedBy(),
      {
        label: 'Last Edited',
        value: ({ updatedAt }) => formatDate(updatedAt),
      },
      csvColumnUpdatedBy(),
    ],
    [],
  )
}
