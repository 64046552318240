import { CareArticle } from './careArticles.types'
import { Course } from './courses.types'
import { Lesson } from './lesson.types'
import { Query } from './query.types'
import { Questionnaire } from './questionnaire.types'

export type Pathway = {
  id: number
  name: string
  points: PathwayPoint[]
  trainingPathwayTypeId: PathwayType
  trainingPathwayTypeName: string
  newTimelinePostCount: number
  isPublished: boolean
  createdAt: string
  updatedAt: string
}

export enum PathwayType {
  caregiver = 5,
  _ = 1, // what's the name?
}

export enum ETrainingPathwayPointType {
  lesson = 'lesson',
  questionnaire = 'questionnaire',
  careArticle = 'careArticle',
  query = 'query',
}

export enum AddTrainingPathwayPointType {
  course = 'course',
  careArticle = 'careArticle',
  taskArticle = 'taskArticle',
  query = 'query',
}

export type PathwayPoint = {
  id: number
  trainingPathwayId: number
  type: ETrainingPathwayPointType
  lessonId: number
  questionnaireId: number
  careArticleId: number
  queryId: number
  order: number
  createdAt: string
  updatedAt: string
  query?: Query
  careArticle?: CareArticle
  questionnaire?: Questionnaire & {
    course: Course
  }
  lesson?: Lesson & {
    course: Course
  }
}

export type PathwayCoursePoint = {
  id: number
  name: string
  type: 'course'
  order: number
  createdAt: string
  updatedAt: string
  points: PathwayPoint[]
}

export type PathwayGroupedPoint = PathwayPoint | PathwayCoursePoint

export type PathwayRequestUpdate = Pick<
  Pathway,
  'id' | 'name' | 'trainingPathwayTypeId' | 'newTimelinePostCount' | 'isPublished'
>

export type PathwayRequestAddCourse = {
  id: number
  courseId: number
}

export type PathwayRequestAddQuery = {
  id: number
  queryId: number
}

export type PathwayRequestEntityType = 'course' | 'lesson' | 'careArticle' | 'query'

export type PathwayRequestAddPoints = {
  id: number
  entityType: PathwayRequestEntityType
  entityIds: number[]
}

export type PathwayRequestAddTimelinePost = {
  id: number
  careArticleId: number
}

export type PathwayRequestDeletePoint = {
  id: number
  pointId: number
}

export type PathwayRequestDeletePoints = {
  id: number
  pointIds: number[]
}

export type PathwayRequestOrderPoints = {
  id: number
  points: number[]
}
